.contato-wrapper{
    z-index: 5;
    position: relative;
        background-color: #fdf9f4;

}

.contato-container{
    display: flex;
    padding: 80px 20px;
    max-width: 950px;
    margin: auto;
    gap: 40px;
        color: #343434;
}

.map-div iframe{
    min-height: 300px;
    
}

.contato-container > div{
    flex: 1;
}

.contato-content{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contato-container h3{
    font-size: 38px;
    margin: 10px 0;
}

.contato-container h2{
    font-family: 'futura-pt-light';
    font-weight: 400;
}

.contato-container .socials{
    display: flex;
    gap: 20px;
}

.contato-container p{
    display: flex;
align-items: center;
gap: 5px; margin-bottom: 5px;
}

.contato-container a{
    color: #343434;
    font-size: 40px;
    padding: 5px;
}