/* .home-container{
} */
.bg-image {
  background-image: url("../../assets/imgs/Design\ sem\ nome.jpg");
  background-size: cover;
  
  height: 850px;
  position: relative;
}


@media(max-width: 1370px){
.bg-image {
  background-image: url('../../assets/imgs/Design\ sem\ nome\ \(1\).jpg');
}
}

@media(max-width: 980px){
.bg-image {
  background-position: 50%;
}
}

@media(max-width: 715px){
.bg-image {
  background-position: 80%;
}
}

@media(max-width: 715px){
.bg-image {
  background-position: 80%;
}
}

.image-overlay {
  background-color: initial;
  background-image: linear-gradient(90deg, #ae9171 30%, #ae8f716e);
  height: 100%;
  left: 0;
  opacity: 0.96;
  position: absolute;
  top: 0;
  width: 100%;
}

.hero-wrapper {
  position: relative;
  height: 850px; /* Altura da imagem de fundo */
}

.hero-container {
  position: absolute;
  top: calc(50% - 130px);
  left: 0;
  transform: scaleX(-1);
  width: 100%;
  color: #fff;
}

.hero-content {
  transform: scaleX(-1); /* Reverte novamente o texto para que apareça normal */
  padding: 0 60px;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.hero-content .nome{
    font-family: 'Times New Roman', Times, serif;
    font-weight: 300;
}

.hero-content p {
  font-size: 20px;
  line-height: 1.3;
}

.hero-content h1 {
  font-size: 38px;
  line-height: 1.2;
  font-weight: 500;
  
}

.hero-content p,
.hero-content h1{
    max-width: 650px;
}

@media (max-width: 768px){
  .bg-image,
  .hero-wrapper{
    height: 650px;
  }

  .hero-container {
  position: absolute;
  top: calc(50% - 110px);
  }
}