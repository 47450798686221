.body-wrapper{
  min-height: 80vh;  
  background-color: #ffffff;
  color: rgb(255, 255, 255);
}

.main-btn{
    cursor: pointer;
    background-color: #ffffff;
    color: #ae9171;
    border-radius: 6.25rem;
    font-family: Inter, sans-serif;
    text-align: center;
    padding: 1rem 3rem;
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1rem;
    width: fit-content;
}


.App{
  font-family: sans-serif;
}

.whats-btn a {
    background-color: #fff;
    border-radius: 99px;
    bottom: 2rem;
    color: #ae9171;
    font-size: 60px;
    opacity: 1;
    padding: 10px;
    position: fixed;
    right: 2rem;
    transition: .4s;
    z-index: 0;
    z-index: 50;
}

.whats-btn a:hover {
    background-color: #ae9171;
    color: #fff;
}


@media (max-width: 768px) {
  .hero-content{
    padding: 0 20px !important;
  }

  .hero-content h1{
    font-size: 28px !important;
  }
  
  .depoimentos-content{
    position: absolute;
    bottom: 5rem;

  }

  .contato-container{
    flex-direction: column;
    text-align: center;
  }

  .contato-content {
    justify-content: center;
    align-items: center;
  }

}

