.pontos-fortes-wrapper{
      background-color: #f9fafa;
      padding: 120px 20px ;
}

.pontos-fortes-container {
  color: #f1f1f1;
  display: flex;
  gap: 20px;
  justify-content: center;
  max-width: 1150px;
  margin: auto;
  position: relative;
}

.pontos-fortes-container div {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 14px;
  color: #e6e6e6;
  background-color: #fff !important;
  padding: 50px 40px;
  flex: 1;
  box-shadow: 0px 5px 15px 0px
    rgba(62.00000000000001, 64.99999999999999, 159, 0.1);
}

.pontos-fortes-container i {
  font-size: 45px;
  color: #e3b576;
  margin-bottom: 30px;
  padding: 30px;
  display: block;
  border-radius: 50%;
}

.pontos-fortes-container p {
  font-size: 16px;
  line-height: 1.6;
  color: #687693;
}

.pontos-fortes-container h4 {
  color: #343434;
  font-weight: 550;
  font-size: 20px;
  margin-bottom: 20px;
}

@media (max-width: 850px) {
  .pontos-fortes-container {
    flex-direction: column;
    padding: 40px 0;
  }
}
