.sobre-wrapper {
  background-color: #ae9171;
}

.sobre-container {
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 20px;
  gap: 40px;
}

.sobre-image {
  max-width:450px;
}

.sobre-image img{
    
    border-radius: 12px;
}


.sobre-container h2 {
  font-size: 36px;
  line-height: 1.3;
  margin-bottom: 10px;
}

.sobre-container h3 {
  font-size: 28px;
  color: #fff;
  margin-bottom: 20px;
}

.sobre-container ul{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sobre-container li {
  font-size: 18px;

}

.sobre-image,
.sobre-text{
    flex: 1;
}

@media (max-width: 768px) {
  .sobre-container {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .sobre-btn-container{
   width: fit-content;
    margin: auto;
  }

  .sobre-container .sobre-image {
    width: 100%;
  }

  .sobre-container li{
    margin: auto;
  }
}

.sobre-btn-container {
  margin-top: 15px;
}

  .sobre-container h2 {
  font-size: 2em; /* Tamanho da fonte para h2 */
  margin-bottom: 0.5em; /* Espaço abaixo do h2 */
}

  .sobre-container h3 {
  font-size: 1.5em; /* Tamanho da fonte para h3 */
  margin-bottom: 1em; /* Espaço abaixo do h3 */
}

  .sobre-container p {
  font-size: 18px; /* Tamanho da fonte para os parágrafos */
  margin-bottom: 1.5em; /* Espaço abaixo de cada parágrafo */
  line-height: 1.5;
}
