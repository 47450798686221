.depoimentos-container{
    min-height: 600px;
    background-image: url('../../assets/imgs/IMG_6536.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 79% 68%;
    position: relative;
    max-width: 1200px;
    margin: auto;
}

@media (min-width: 1200px) {
.depoimentos-container,
.depoimentos-container .overlay{
    border-radius: 30px;
}
}

.depoimentos-container .overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ae90717e;

}

.depoimentos-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.depoimentos-content{
    position: relative;
    z-index: 10;
    color: #fff;
    max-width: 590px;
    text-align: center;
    padding: 0 20px;
    padding-top:140px;
}

.depoimentos-content h2{
    line-height: 1.2;
    font-size: 2.5rem;
}

.depoimentos-content .main-btn{
    margin: auto;
    margin-top: 15px;
    display: block;
}

@media (max-width: 460px){
    .depoimentos-content h2{
    line-height: 1.2;
    font-size: 1.8rem;
}

.depoimentos-container{
    background-position: 50%;

}

}