.FAQ-wrapper {
  background-color: #ae9171;
  padding: 80px 0;
}

.FAQ-container {
  margin: 0 auto;
  border-radius: 8px;
  color: #020202;
  padding: 0;
}

.FAQ-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.FAQ-item {
  padding: 10px;
}

.FAQ-question {
  font-weight: bold;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  z-index: 10;
  position: relative;

  width: 100%;
}

.FAQ-question:hover {
  background-color: #ae9071e1;
}

.FAQ-answer {
  position: relative;
  z-index: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-40px);
  transition: .3s;
  border: 1px solid #ae9171;
  border-radius: 4px;
  line-height: 1.3;
  border-top: 0;

  padding: 0 20px;
  
  
}

.FAQ-answer.visible {
  max-height: 500px;
  opacity: 1;
  transform: translateY(-6px);
  line-height: 1.3;
  padding: 20px;
  
}

.FAQ-wrapper {
  padding-top: 120px;
  background-color: #f9fafa;
}

.FAQ-container {
  margin: 20px;
  padding: 0px 20px 0 20px;
  color: #343434;
  max-width: 850px;
  margin: auto;
}

.FAQ-wrapper h2 {
  font-size: 40px;
  font-weight: 500;
  color: #030611;
  text-align: center;
  margin-bottom: 30px;
}

.FAQ-item {
  padding: 5px 0;
  padding-bottom: 10px;
}

button.FAQ-question {
  width: 100%;
  gap: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ae9171;
  color: #fff;
  transition: 0.3s;
  padding: 20px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;

}

.FAQ-question button:hover {
  transform: scale(1.02);
}

.FAQ-question span {
  color: #fff;
  background-color: #343434;

  font-size: 20px;
  padding: 2px 8px;
  border-radius: 5px;
}

.FAQ-item h3 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin-top: 0;
  line-height: 1.3;
  text-align: left;
}

.FAQ-item p {
  margin-top: 10px;
  color: #343434cc;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
}
