.palestras-container {
  padding: 140px 50px;
  margin: auto;
  max-width: 1250px;
}

.cards-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.palestras-container h2{
    color: #343434;
    margin-bottom: 20px;
    text-align: center;
    font-size: 32px;
}

.card {
  background-color: #ae9171;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
}

.card-content{
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 1.3;
  max-height: 172px;
  height: 172px;

}

.card-image {
  width: 100%;
  min-height: 200px;
  height: auto;
    max-height: 330px;
  overflow: hidden;
  line-height: 0;
  background-color: #fff;
}


.card-title {
  font-size:18px;
  margin: 15px 0;
  margin-top: 0;
  line-height: 1.3;
  font-weight: 600;
}

.card-description {
  font-size: 14px;
  margin: 10px 0;
  line-height: 1.3;
}

.card-link {
  display: block;
  color: #fff;
  border-bottom: 1px solid #fff;
  width: fit-content;

}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

.slick-slide>div {
    padding: 10px;
}

.custom-arrow {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  z-index: 1 !important;
}

.custom-next{
  right: -50px !important; 
}
.custom-prev{
  left: -50px !important;
}

.custom-arrow i {
  color: #ae9171;
  font-size: 60px;
}

.custom-arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}




