.servicos-container{
    margin-top: 80px;

    color: #ae9171;
}

.especialidades-wrapper {
  background-color: #fcf5ec;
  padding-bottom: 120px;
}

.especialidades-container {
  max-width: 1150px;
  padding: 0 20px;
  margin: auto;
}

.especialidades-container img{
  max-width: 120px;
  background-color: #ae9171;
  border-radius: 50%;
}

.especialidades-container .header{
    margin: auto;
  margin-bottom: 20px;
  padding-top: 100px;
  padding-bottom: 25px;
  max-width: 700px;
  font-size: 40px;
  font-weight: 500;
  color: #343434;
  text-align: center;
}

.especialidades-container h2 {
  font-size: 40px;
  font-weight: 500;
  color: #343434;
  text-align: center;
}

.especialidades-container .servicos-grid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.servicos-grid > div {
  background-color: #fff;
  box-shadow: 0px 5px 15px 0px
    rgba(62.00000000000001, 64.99999999999999, 159, 0.1);
  transition: 0.3s;
  border-radius: 8px;
  padding: 40px 25px;
}

.servicos-grid a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #687693;
  text-align: center;
  margin-top: 10px;
}

.servicos-grid > div div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
}

.servicos-grid > div div i{
    padding: 20px;
}

.servicos-grid i {
  color: #ae9171;
  font-size: 40px;
  font-weight: 400;
}

.servicos-grid p {
  color: #687693;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
}

.servicos-grid h3 {
  color: #343434;
  font-size: 22px;
  text-align: center;
}
